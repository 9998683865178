.profile-container {
    display: inline-flex;
    padding: 0 6px 0 6px;
    align-items: center;
}

.profile-container:hover {
    background-color: #349ef4
}

.no-select-text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}