
.info-area {
    text-align: center;
    padding: 28px 0;
    color: #9E9E9E;
}

.info-area > *:not(a):not(.example-img) {
    padding: 0 28px;
}
