.v-card.gs-list-time-picker > .v-card__text {
    height: 250px;
    padding: 0;
    margin: 0;
}

.v-card.gs-list-time-picker > .v-card__text .v-list.v-input--selection-controls {
    margin: 0;
}

.v-card.gs-list-time-picker .v-input--radio-group__input .v-radio.v-list-item {
    margin: 0;
    padding-right: 0;
    min-height: auto;
}

.v-card.gs-list-time-picker .v-input--radio-group__input .v-radio.v-list-item .v-input--selection-controls__input {
    padding: 24px 0;
}

.v-card.gs-list-time-picker .v-input--radio-group__input .v-radio.v-list-item .v-label {
    padding: 16px 0;
}
