html {
    overflow: hidden;
    overflow-y: scroll;
}

.booking-main {
    overflow-x: hidden;
    padding: 0 !important;
    margin-top: 64px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

label img:hover,
.job_type_col:not(.radioSelected):hover,
.vehicle_type_col:not(.radioSelected):hover,
.gs-open-edit-modal-cargo-item:hover,
.gs-open-modal-delete-cargo-item:hover,
.material-icons.vehicle_type:hover,
.gs-thumbnails-wrapper img#add-photos,
.gs-remove-photo,
#weight-subtract:hover, #weight-add:hover {
    cursor: pointer;
}

li.gs-custom-autocomplete-entry span, .dropdown-content li > a, .dropdown-content li > span {
    color: rgba(0, 0, 0, 0.87);
}

.radioSelected {
    border: 2px solid #2979ff;
    border-radius: 2px;
    background-color: #e3f2fd;
}

.gs-open-modal-delete-cargo-item {
    z-index: 99;
}

.job_type_col,
.vehicle_type_col {
    transition: background-color .5s;
    border: 2px solid #fff;
    border-radius: 2px;
    background-color: #fff;
}

.vehicle_type_col.radioSelected {
    border: 2px solid #2979ff !important;
    border-radius: 2px;
    background-color: #e3f2fd !important;
}

#modal-cargo-item {
    max-height: 80%;
}

#modal-cargo-item .modal-content {
    min-height: 400px;
}

@media (min-width: 576px) {
    #modal-cargo-item ul.select-dropdown {
        max-height: 280px !important;
        margin-top: -120px !important;
        top: 0px !important;
    }
}

.radio-hidden {
    padding-left: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87) !important;
    left: 0 !important;
    float: left;
}

.vehicle_type_col p {
    padding: 5px 10px 15px 0;
}

.vehicle-description {
    font-weight: 300;
    color: rgba(0, 0, 0, 1);
}

.clear {
    clear: both;
}

.material-tooltip li {
    text-align: left;
}

#invalid_code {
    color: #B00020;
}

#estimate-promocode {
    padding-bottom: 5px;
}

#estimate-pickup,
#estimate-dropoff,
#estimate-time,
#estimate-price {
    font-weight: 300;
    line-height: 24px;
}

.lined {
    text-decoration: line-through;
}

.nav-extended div.nav-wrapper {
    /*line-height: normal;*/
}

[type="radio"] + label.radio-hidden:after,
[type="radio"] + label.radio-hidden:before,
[type="radio"]:checked + label.radio-hidden:after,
[type="radio"]:checked + label.radio-hidden:before {
    content: '' !important;
    border: none !important;
    height: 0 !important;
    width: 0 !important;
    background-color: transparent !important;
}

.material-icons.vehicle_type {
    color: #999;
}

.radioSelected .material-icons.vehicle_type {
    color: #999;
}

label img {
    max-width: 135px !important;
}

@media all and (max-width: 936px) {
    .breadcrumb-container .breadcrumb {
        font-size: 0.9em;
    }

    .breadcrumb-container .breadcrumb::before {
        margin: 0 2px 0 1px;
    }

    .modal .modal-content {
        font-size: 0.8em;
    }

    .modal-content ol {
        padding-left: 0;
    }

    .modal-content ol li {
        /*margin-left: 24px;*/
    }
}

.job_type_col label {
    width: 100%;
}

.job_type_img {
    padding-top: 15px;
    max-height: 200px;
}

header li a {
    text-transform: uppercase;
}

@media all and (max-width: 600px) {
    .breadcrumb span {
        width: auto;
        max-height: 56px;
        display: inline-block;
        vertical-align: top;
        transition: width .5s;
    }

    .breadcrumb:not(.current_step_breadcrumb) span {
        max-width: 30px;
        overflow: hidden;
    }

    .badge.new {
        font-size: .6em !important;
    }

    .job_type_img {
        max-height: 100px;
    }

    .gs-items-wrapper .gs-body.row input {
        padding: 0px 0px;
    }

    .gs-items-wrapper .gs-body.row .input-field {
        padding: 0 0.25em;
    }

    #open-add-modal-cargo-item, #what-are-moving-attach-photo-wrapper > div {
        width: 100%;
    }

    #what-are-moving-attach-photo-wrapper .file-path-wrapper {
        padding-left: 0;
    }

    #breadcrumbs_nav {
        display: none;
    }

    .card {
        margin: 0rem -0.5rem 1rem -0.5rem;
    }

    .container {
        width: 95%;
    }

    .card-title {
        margin-top: 0px;
    }

    #form_step_where_next {
        width: 100%;
    }

    .full-width-buttons {
        text-align: center;
    }

    .full-width-buttons {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    label img {
        max-width: 100% !important;
    }

    .job_type1,
    .job_type2,
    .job_type3 {
        display: block;
    }

    #modal-cargo-item .modal-footer,
    #location-insert-details-modal .modal-footer {
        position: relative;
        bottom: 0;
        width: 100%;
        left: 0;
        text-align: center;
        height: unset;
    }

    #modal-cargo-item .modal-footer .modal-cargo-item-submit-close,
    #location-insert-details-modal .modal-footer #location-details-cancel {
        display: none;
    }

    #modal-cargo-item .modal-footer input,
    #location-insert-details-modal .modal-footer input {
        width: 48%;
    }

    #modal-cargo-item .modal-footer .mobile-full-width,
    #location-insert-details-modal .modal-footer .mobile-full-width {
        width: 100%;
        text-align: left;
    }

    #modal-cargo-item .modal-content > h5,
    #summary-photos,
    #summary-additional-info {
        display: none;
    }

    #modal-cargo-item.gs-mobile-full-screen,
    #location-insert-details-modal.gs-mobile-full-screen {
        top: 0px !important;
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        max-width: 100vw;
    }

    #modal-cargo-item.gs-mobile-full-screen .gs-title-bar,
    #location-insert-details-modal.gs-mobile-full-screen .gs-title-bar {
        display: block !important;
    }

    #modal-cargo-item.gs-mobile-full-screen span.gs-title,
    #location-insert-details-modal.gs-mobile-full-screen span.gs-title {
        font-size: 1.4em;
    }

    h5 > .location-model-header {
        display: none;
    }

    .gs-checkboxes-wrapper {
        padding-left: 36px
    }

    #surge_modal .modal-footer {
        height: unset;
    }
}

.card.disclaimer {
    margin-top: 1.5rem !important;
}

.gs-items-wrapper .row.gs-cargo-item {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    padding: 0px !important;
}

.gs-items-wrapper .row.gs-cargo-item .col.gs-cargo-item-name {
    padding-top: 12px;
}

.gs-items-wrapper .row.gs-body input {
    border-bottom: 0px solid #4caf50;
    -webkit-box-shadow: 0 0px 0 0 #4caf50;
    box-shadow: 0 0px 0 0 #4caf50;
    background-color: #FFFFFF;
    padding: 0px 8px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.gs-items-wrapper .row.gs-cargo-item .right-align input {
    text-align: right;
}

.gs-items-wrapper .row.gs-cargo-item .gs-buttons-wrapper {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 18px;
    margin-bottom: 18px;
}

.gs-items-wrapper .row.gs-cargo-item .gs-weight {
    font-size: 12px;
}

.gs-items-wrapper .row.gs-cargo-item > div {
    line-height: 24px;
}

.gs-items-wrapper .row.gs-cargo-item .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.gs-items-wrapper .row.gs-cargo-item .col {
    padding-left: 0px !important;
}

.gs-cargo-item-summary .gs-cargo-item > div {
    margin: 10px 0;
}

#modal-cargo-item, #modal-delete-cargo-item {
    max-width: 500px;
}

#modal-cargo-item .gs-title-bar,
#location-insert-details-modal .gs-title-bar {
    display: none;
}

#modal-cargo-item .autocomplete-content {
    position: absolute;
    margin-bottom: 0px;
}

#modal-cargo-item #weight-add, #modal-cargo-item #weight-subtract {
    padding-left: 6px;
    padding-right: 6px;
    min-width: 40px;
}

#modal-cargo-item .gs-weight-buttons-wrapper {
    padding: 0px;
}

#location-insert-details-modal {
    max-width: 500px;
    max-height: 90%;
}

.gs-buttons, #modal-cargo-item #weight-add, #modal-cargo-item #weight-subtract {
    width: 24px;
    margin-left: 5%;
    border: 2px solid #42A5F5;
    background-color: #42A5F5;
    cursor: pointer;
    border-radius: 3px;
}

.gs-buttons-disabled > span {
    pointer-events: none !important;
}

.gs-buttons-disabled > span > img {
    border: 2px solid #E0E0E0;
    background-color: #E0E0E0;
}

#modal-cargo-item .modal-footer,
#location-insert-details-modal .modal-footer {
    padding: 4px 24px 24px 24px;
}

input#needs-carry-cargo ~ .collapsible-content {
    display: none;
    height: 135px;
    padding: 20px 0 0 0;
    clear: right;
}

#modal-cargo-item-add-another-div {
    margin-bottom: 4px;
}

div#cargo_list-error {
    padding-top: 12px;
}

#open-add-modal-cargo-item {
    margin: 8px 0px;
}

#modal-progress {
    visibility: hidden;
    margin-top: 0;
    margin-bottom: 0px;
}

.gs-custom-title {
    font-size: 15px;
    font-weight: 400;
    color: rgb(158, 158, 158) !important;
    margin-top: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gs-thumbnails-wrapper {
    height: 130px;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    white-space: nowrap;
}

.gs-thumbnails-wrapper > span {
    display: inline-block;
    position: relative;
}

#thumbnails-wrapper {
    margin-left: 11px !important;
}

.gs-thumbnails-wrapper > span img.gs-thumbnail {
    border-radius: 3px;
    height: 100px;
    width: 100px;
    margin: 8px 16px 4px 0px;
    object-fit: cover;
}

.gs-remove-photo {
    position: absolute;
    display: block;
    margin: auto;
    top: 5px;
    right: 5px;
}

.gs-thumbnails-wrapper img#add-photos {
    border: 10px solid #42A5F5;
    background-color: #42A5F5;
}

#additional_info::-webkit-input-placeholder { /* Chrome */
    color: rgba(0, 0, 0, 0.60);
}

#additional_info:-ms-input-placeholder { /* IE 10+ */
    color: rgba(0, 0, 0, 0.60);
}

#additional_info::-moz-placeholder { /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.60);
    opacity: 1;
}

#additional_info:-moz-placeholder { /* Firefox 4 - 18 */
    color: rgba(0, 0, 0, 0.60);
    opacity: 1;
}

.toggle-list-button:hover {
    cursor: pointer;
}

ul.collection-no-border {
    border: 0px;
}

#summary-collection-card ul li.collection-item {
    padding-left: 0;
}

#promocode_panel .card-action {
    padding: 8px;
}

#promocode_panel .card-action button.btn-flat {
    padding: 0 16px;
}

#promocode_panel #promocode-field {
    height: 62px;
}

.gs-review-label {
    color: #9e9e9e;
    font-weight: 400;
    font-size: 12px;
}

.gs-review-text {
    font-weight: 400 !important;
}

.price-breakdown-primary-price-text {
    font-weight: 500 !important;
}

.gs-card-equal-height-wrapper {
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
}

.gs-card-equal-height {
    height: 93%;
}

.scrollable-textarea {
    max-height: 150px;
    overflow-y: auto !important;
}

section .card-content .row:last-of-type {
    margin-bottom: 0;
}

#form_promocode .grey-text, #step_what .grey-text {
    font-size: 0.8em;
    margin: 0;
}

.ios-iphone-button-below-fold-fix {
    margin-bottom: 12vh !important;
}

#price-break-down > tbody > tr > td {
    padding: 2px 5px;
}

#price-break-down > tbody > tr.before-divider > td {
    padding-bottom: 6px !important;
}

#price-break-down > tbody > tr.after-divider > td {
    padding-top: 6px !important;
}

.price-breakdown-divider {
    border-bottom: 1px solid #CCCCCC;
    margin: 4px 0;
}

#price-break-down {
    width: -webkit-fill-available;
    margin: 0 .75rem;
}

.gs-label {
    font-weight: 300;
}

.truck-img {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 200px;
    margin: auto;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.driver-img {
    position: absolute;
    top: 55%;
    left: 30%;
    width: 15%;
    height: 44%;
    z-index: 2;
    background-image: url("//static.goshare.co/project_types/delivery_pro.png");
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.helper-img {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 15%;
    height: 44%;
    z-index: 2;
    background-image: url("//static.goshare.co/project_types/helper.png");
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.driver-helper-container {
    display: inline-flex;
    position: relative;
    width: 100%;
}

.vehicle-description {
    font-size: .8rem;
    padding: 0 7px 3px 0 !important;
}

.vehicle-details {
    display: flex;
    flex-flow: column;
}

.truncated {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul#booking-disclaimer {
    padding-left: 16px !important;
}

ul#booking-disclaimer li {
    list-style-type: disc;
}

.v-list-item {
    margin-left: 0;
}

.v-stepper {
    overflow: unset;
    box-shadow: none;
}

.v-stepper__header {
    box-shadow: none;
    background-color: #fafafa;
}

.v-stepper__items {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.v-stepper__items.no-background {
    box-shadow: none;
}

.v-stepper__items.no-background .v-stepper__content {
    background-color: #fafafa;
}

.v-stepper__content.step-review > .v-stepper__wrapper {
    overflow: visible;
}

.theme--light.v-application {
    background-color: #fafafa;
}

.gs-timeline-address.v-timeline {
    padding-left: 7px;
}

.gs-timeline-address .v-timeline-item {
    padding: 10px 0;
    border: #fff 1px solid;
    border-radius: 5px;
}

.gs-timeline-address .v-timeline-item.is-hover {
    background: #f1f6f6;
    cursor: grab;
}

.gs-timeline-address .v-timeline-item.drag-and-drop {
    background: #ecf3f3;
}

.gs-timeline-address .v-timeline-item .mdi-drag {
    visibility: hidden;
    position: relative;
    right: 24px;
    bottom: 22px;
}

.gs-timeline-address .v-timeline-item.is-hover .mdi-drag {
    visibility: visible;
}

.gs-timeline-address .v-timeline-item .timeline-item-icon-text {
    display: table;
    margin: 25px auto 0;
}

.gs-timeline-address .gs-timeline-item-divider {
    position: relative;
    margin: 2px 1px;
    padding-left: 9px;
    border: white 2px solid;
    transform-style: preserve-3d;
}

.gs-timeline-address .gs-timeline-item-divider::before {
    bottom: 0;
    content: "";
    height: 7px;
    position: absolute;
    top: -7px;
    width: 2px;
    background-color: #e0e0e0;
}

.gs-timeline-address .gs-timeline-item-divider::after {
    bottom: 0;
    content: "";
    height: 7px;
    position: absolute;
    top: 0;
    width: 2px;
    background-color: #e0e0e0;
}

.gs-timeline-address .v-timeline-item:nth-of-type(2) > .v-timeline-item__divider::before {
    background: transparent;
}

.gs-timeline-address .v-timeline-item:nth-of-type(2) > .v-timeline-item__divider::after {
    top: 25%;
}

.gs-timeline-address .gs-timeline-item-divider:first-child::before,
.gs-timeline-address .gs-timeline-item-divider:first-child::after {
    background: transparent;
}

.gs-timeline-address .gs-timeline-item-divider.gs-drop-active {
    border-color: #2196f3;
    background-color: #2196f3;
}

.gs-timeline-address .gs-timeline-item-divider.gs-drop-active::before,
.gs-timeline-address .gs-timeline-item-divider.gs-drop-active::after {
    transform: translateZ(-1px);
}

.gs-timeline-address .v-timeline-item__divider div[role=button] {
    cursor: grab;
}

.gs-timeline-address .v-timeline-item__divider::before {
    top: -18%;
}

.gs-timeline-address .v-timeline-item__divider::after {
    top: 18%;
}

.gs-timeline-address .v-timeline-item:last-child > .v-timeline-item__divider::before {
    top: -40%;
}

/* Used to get booking timeline in a line with email, date, time */
.v-timeline-item__divider {
    min-width: 24px;
    margin-right: 9px;
}

.v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 24px);
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(12px - 1px)
}

.categories-skeleton > .v-skeleton-loader__list-item-avatar-two-line {
    margin-bottom: 12px;
    height: 95px;
}

.v-tabs {
    position: absolute;
    left: 0;
    top: 0;
}

.v-window.v-item-group {
    position: relative;
    left: 0;
    top: 25px;
}

.gs-word-break {
    word-break: normal;
}

/* Vuetify hacks for Firefox 52 */
.v-time-picker-clock {
    height: 270px;
}

.timeline-item-icon-text {
    color: white;
    font-size: 12px;
}

.v-timeline-item__divider::before {
    bottom: 0;
    content: "";
    height: 100%;
    position: absolute;
    top: -50%;
    width: 2px;
    background-color: #e0e0e0;
}

.v-timeline-item__divider::after {
    bottom: 0;
    content: "";
    height: 100%;
    position: absolute;
    top: 50%;
    width: 2px;
    background-color: #e0e0e0;
}

.theme--light.v-timeline::before {
    background: transparent;
}

.v-timeline-item:first-child > .v-timeline-item__divider::before,
.v-timeline-item:last-child > .v-timeline-item__divider::after {
    background: transparent;
}

.gs-vue-recaptcha > .v-input__control > .v-input__slot {
    display: contents;
}

.error-add-helper .v-input__control {
    min-height: 0 !important;
}

.error-add-helper .v-input__control .v-text-field__details {
    padding: 0 3px;
}

/* Sometimes v-cleave does not work when unfocusing field or pasting */
#booking_promo_code {
    text-transform: uppercase;
}

.cargo-item-list-what-step .v-list-item__subtitle.item-notice {
    white-space: normal !important;
}

.gs-demo-banner {
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    border-top: 4px solid #FF5722;
    line-height: 0;
    overflow: hidden;
    z-index: 6;
    font-size: 0.8rem;
    font-weight: bold;
}

.gs-demo-banner > .gs-demo-content {
    color: #fff;
    position: relative;
    display: inline-block;
    padding: 0 0.8rem 0.2rem;
    text-transform: uppercase;
    letter-spacing: .05em;
    background: #FF5722;
    line-height: 1;
    font-size: 0.7rem;
}

.gs-demo-banner > .gs-demo-content::before {
    left: -2px;
}

.gs-demo-banner > .gs-demo-content::after {
    right: -2px;
}

.gs-demo-banner > .gs-demo-content::before,
.gs-demo-banner > .gs-demo-content::after {
    content: "";
    position: absolute;
    width: 4px;
    top: 0;
    height: 21px;
    z-index: -1;
    background: #FF5722;
    transform: perspective(2px) rotateX(-30deg);
}
