/**
navigation_background: #2196f3, Blue 500
accent_color: #2979ff, Accent Blue 400
main_button_background: #4caf50, Green 500
secondary_button_background: #42a5f5, Blue 400
negative_button_background: #d32f2f, Red 500
error_color: #b00020
 */

html, body {
    margin: 0;
    padding: 0;
}

body {
    background-color: #f2f2f2;
}

footer {
    font-weight: 300;
}

footer a:not(.rrssb-buttons a):not(.bbb-link-img):hover {
    border-bottom: 2px solid;
}

hr.gs-line {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.input-field > .helper-text {
    font-size: 12px;
    color: #9e9e9e;
    display: inline-block;
    margin-top: 4px;
}

.white-text a {
    color: white;
}

.logo {
    padding: 0;
    background-color: #2196f3;
}

.logo:hover {
    background-color: #2196f3;
}

.logo-img {
    margin: 12px 0;
    height: 40px;
}

#logo-container {
    height: 64px;
    text-align: center;
}

#logo-container:not(.logo #logo-container) {
    display: inline-block;
}

.btn {
    background-color: #4caf50;
}

.btn:hover {
    background-color: #4caf50c7;
}

.gs-clear-focus-bg:focus {
    background: transparent !important;
}

button.btn.main-button,
input[type=button].btn.main-button,
input[type=submit].btn.main-button {
    background-color: #4caf50 !important;
    color: white !important;
}

.main-button.waves-effect {
    background-color: #4caf50;
}

button.btn.main-button.disabled {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

button.btn.secondary-button,
input[type=button].btn.secondary-button,
input[type=submit].btn.secondary-button {
    background-color: #42a5f5 !important;
    color: white !important;
}

.secondary-button.waves-effect {
    background-color: #42a5f5;
}

button.btn.negative-button,
input[type=button].btn.negative-button,
input[type=submit].btn.negative-button {
    background-color: #d32f2f !important;
    color: white !important;
}

.negative-button.waves-effect {
    background-color: #d32f2f;
}

button.btn-flat.text-button,
input[type=button].btn-flat.text-button,
input[type=submit].btn-flat.text-button {
    background-color: transparent !important;
}

button.btn-flat.text-button:hover,
input[type=button].btn-flat.text-button:hover,
input[type=submit].btn-flat.text-button:hover {
    background-color: transparent !important;
    color: #2979ff !important;
    border: 1px solid #d7d7d7;
}

.text-button.waves-effect {
    background-color: transparent;
    color: #2979ff !important;
    border: 1px solid #d7d7d7;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
    border: 2px solid #2196f3 !important;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
    background-color: #2196f3;
}

@media all and (max-width: 600px) {
    #logo-container {
        height: 56px;
    }

    .logo-img {
        margin: 8px 0;
        height: 40px;
    }

    .gs-card-equal-height-wrapper {
        display: block !important;
    }
}

.materialize-textarea {
    padding: 0 !important;
}

.materialize-textarea:focus {
    padding: .8rem 0 1.6rem 0 !important;
}

#logo-container:hover {
    background-color: transparent !important;
}

.nav-header {
    /*margin-left: 162px; !* logo is 162px wide *!*/
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.card-even-margin-bottom {
    margin-bottom: 0.5rem !important;
}

.col.no-padding {
    padding: 0 !important;
}

@media only screen and (max-width: 600px) {
    .no-padding-left {
        padding-left: .75rem !important;
    }

    .no-padding-right {
        padding-right: .75rem !important;
    }

    #toast-container {
        top: 0 !important;
    }
}

.breadcrumb.disabled,
.breadcrumb.disabled:before {
    color: rgba(0, 0, 0, 0.4);
}

.breadcrumb.disabled:last-child {
    color: rgba(0, 0, 0, 0.4);
}

.breadcrumb,
.breadcrumb:last-child,
.breadcrumb:before {
    color: rgba(0, 0, 0, 1);
}

.hidden {
    display: none;
}

.breadcrumb-container {
    /*height: auto;*/
}

.breadcrumb-container .col.s12 {
    height: 64px;
}

.breadcrumb.current_step_breadcrumb {
    color: #2196F3;
}

a.breadcrumb.clickable_link:hover {
    cursor: pointer;
}

.input-field div.invalid:not(.select-wrapper) {
    position: relative;
    top: -1rem;
    left: 0rem;
    font-size: 0.8rem;
    color: #B00020;
}

.gs-msg-promocode {
    position: relative;
    top: -0.7rem;
    left: 0rem;
    font-size: 0.8rem;
    color: #B00020;
}

input[type=hidden] ~ div[id$="-error"] {
    font-size: 0.8rem;
    color: #B00020;
}

.small-text {
    font-size: 0.6em;
}

.review_caption_icon {
    display: inline-block;
    margin-right: 1rem;
}

.review_caption_icon.registration-state {
    margin-left: 1rem;
}

.valign-wrapper .top {
    -webkit-align-items: baseline;
    align-items: baseline;
}

li.collection-header {
    font-size: 24px;
    font-weight: 300
}

.collection.with-header.no-item-padding .collection-item {
    padding-left: 20px;
}

.collection a .linked-collection-item:not(a:last-child .collection-item) {
    border-bottom: 1px solid #e0e0e0;
}

.collapsible .collection-item {
    background-color: #fff;
    line-height: 1.5rem;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
}

div iframe {
    max-width: 100% !important;
}

.gs-modal-image {
    width: 100%;
    height: auto;
}

.gs-modal-title {
    font-size: 24px;
    font-weight: 300;
    padding: 0 .75rem;
    margin-bottom: 12px;
}

.gs-text-info {
    font-size: 16px;
    font-weight: 300;
}

.switch-describe > p {
    margin: 0;
}

.switch-describe > p.grey-text {
    font-size: 12px;
}

.gs-modal-label {
    font-weight: 300;
}

.progress-container {
    height: 4px;
    position: static;
}

.progress-container .progress {
    margin: 0;
    display: none;
}

.progress-container.page_top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

i.inverted {
    transform: scale(-1, 1);
}

i.material-icons, i.material-icons + span {
    vertical-align: middle;
}

a.cta {
    width: 100%;
}

.relative {
    position: relative;
}

.breadcrumb span.truncate {
    display: inline-block;
}

.radar-container {
    display: block;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    min-height: 205px;
}

.centralizer {
    position: absolute;
    width: 100%;
    left: 50%;
}

.radar-location {
    position: absolute;
    width: 50px;
    height: 50px;
    left: -25px;
    top: 50px;
    text-align: center;
    z-index: 10;
}

.radar-location i {
    font-size: 50px;
}

.radar-pickup {
    position: absolute;
    width: 100%;
    left: -50%;
    top: 100px;
    text-align: center;
    z-index: 10;
}

.radar-circle {
    z-index: 5;
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 100px;
    border: 2px solid #2196f3;
    border-radius: 50%;
}

.radar.delay_1 {
    animation-delay: 0.25s;
}

.radar.delay_2 {
    animation-delay: 0.5s;
}

.radar.delay_3 {
    animation-delay: 0.75s;
}

.radar {
    animation: radar ease-out 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 50% 50%;
    -webkit-animation: radar ease-out 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: radar ease-out 1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: alternate;
    -moz-transform-origin: 50% 50%;
    -o-animation: radar ease-out 1s;
    -o-animation-iteration-count: infinite;
    -o-animation-direction: alternate;
    -o-transform-origin: 50% 50%;
    -ms-animation: radar ease-out 1s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-direction: alternate;
    -ms-transform-origin: 50% 50%;
}

@keyframes radar {
    0% {
        opacity: 1;
        width: 2px;
        height: 2px;
        left: 0;
        top: 100px;
    }
    100% {
        opacity: 0;
        width: 200px;
        height: 200px;
        left: -100px;
        top: 0;
    }
}

@-moz-keyframes radar {
    0% {
        opacity: 1;
        width: 2px;
        height: 2px;
        left: 0;
        top: 100px;
    }
    100% {
        opacity: 0;
        width: 200px;
        height: 200px;
        left: -100px;
        top: 0;
    }
}

@-webkit-keyframes radar {
    0% {
        opacity: 1;
        width: 2px;
        height: 2px;
        left: 0;
        top: 100px;
    }
    100% {
        opacity: 0;
        width: 200px;
        height: 200px;
        left: -100px;
        top: 0;
    }
}

@-o-keyframes radar {
    0% {
        opacity: 1;
        width: 2px;
        height: 2px;
        left: 0;
        top: 100px;
    }
    100% {
        opacity: 0;
        width: 200px;
        height: 200px;
        left: -100px;
        top: 0;
    }
}

@-ms-keyframes radar {
    0% {
        opacity: 1;
        width: 2px;
        height: 2px;
        left: 0;
        top: 100px;
    }
    100% {
        opacity: 0;
        width: 200px;
        height: 200px;
        left: -100px;
        top: 0;
    }
}

@media all and (max-width: 710px) {
    .breadcrumb-container .breadcrumb,
    .breadcrumb-container .breadcrumb::before {
        font-size: 1em;
    }
}

@media all and (max-width: 528px) {
    .breadcrumb-container .breadcrumb {
        font-size: 0.9em;
    }

    .breadcrumb-container .breadcrumb::before {
        margin: 0 2px 0 1px;
    }

    .modal .modal-content {
        font-size: 0.8em;
    }

    .modal-content ol {
        padding-left: 0;
    }

    .modal-content ol li {
        /*margin-left: 24px;*/
    }
}

@media all and (max-width: 412px) {
    .breadcrumb span {
        width: auto;
        max-height: 56px;
        display: inline-block;
        vertical-align: top;
        transition: width .5s;
    }

    .breadcrumb:not(.current_step_breadcrumb) span {
        max-width: 30px;
        overflow: hidden;
    }

    .badge.new {
        font-size: .6em !important;
    }
}

#full_overlay,
#notSupportedOverlay {
    position: fixed;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 99;
}

#full_overlay div {
    margin: 30px auto;
    width: 70%;
    padding: 20px;
    background-color: #fafafa;
}

#notSupportedOverlay {
    background-color: rgba(0, 0, 0, 0.95);
    padding-top: 30px;
}

.gs-cargo-item {
    padding: 12px 4px !important;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.gs-right-button-24px {
    position: absolute;
    right: 0;
    height: 36px;
    padding-top: 6px;
    padding-right: 16px;
}

.gs-right-button-24px img {
    height: 24px;
}

.gs-card-equal-height-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.gs-card-equal-height {
    padding-top: 0;
    flex-basis: 280px;
}

.row-flex-item {
    display: flex;
    flex-flow: column;
}

.gs-warning-color {
    color: #dcb600;
}

.gs-card-ifo {
    margin: 0;
}

.create-account-link {
    display: inline-block;
    margin-bottom: 12px;
}

/**
 Default Tabs color when focused to blue
 */
.tabs .tab a {
    color: #2979ff;
}

.tabs .tab a:hover {
    background-color: white;
    color: #2979ff;
}

.tabs .tab a.active {
    background-color: white;
    color: #2979ff;
}

.tabs .indicator {
    background-color: #2979ff;
}

/**
 Default .validate and .valid label color when focused to blue.
 */
input[type=text].valid:focus + label,
input[type=text].validate:focus + label,
input[type=text]:not(.browser-default):focus:not([readonly]) + label,
.input-field textarea:focus + label,
input[type=password].valid:focus + label,
input[type=password].validate:focus + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=number]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea.valid:focus + label,
textarea.materialize-textarea.validate:focus + label,
.radioSelected label, .radioSelected p,
li.gs-custom-autocomplete-entry span.highlight {
    color: #2979ff;
}

/**
 Default input border bottom when focused to blue.
 A .valid input should always show blue on focus.
 */
input.valid:not([type]):focus,
input[type=text].valid:not(.browser-default):focus,
input[type=password].valid:not(.browser-default):focus,
input[type=email].valid:not(.browser-default):focus,
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url].valid:not(.browser-default):focus,
input[type=time].valid:not(.browser-default):focus,
input[type=date].valid:not(.browser-default):focus,
input[type=datetime].valid:not(.browser-default):focus,
input[type=datetime-local].valid:not(.browser-default):focus,
input[type=tel].valid:not(.browser-default):focus,
input[type=number].valid:not(.browser-default):focus,
.input-field textarea:focus,
input[type=search].valid:not(.browser-default):focus,
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
    border-bottom: 1px solid #2979ff !important;
    -webkit-box-shadow: 0 1px 0 0 #2979ff !important;
    box-shadow: 0 1px 0 0 #2979ff !important;
}

/**
 Default input border bottom when focused to blue.
 This does not override with !important because a validate input may be invalid,
 which should show then red color.
 */
input.validate:not([type]):focus,
input[type=text].validate:not(.browser-default):focus,
input[type=password].validate:not(.browser-default):focus,
input[type=email].validate:not(.browser-default):focus,
input[type=url].validate:not(.browser-default):focus,
input[type=time].validate:not(.browser-default):focus,
input[type=date].validate:not(.browser-default):focus,
input[type=datetime].validate:not(.browser-default):focus,
input[type=datetime-local].validate:not(.browser-default):focus,
input[type=tel].validate:not(.browser-default):focus,
input[type=number].validate:not(.browser-default):focus,
input[type=search].validate:not(.browser-default):focus,
textarea.materialize-textarea.validate:focus,
.select-wrapper.validate > input.select-dropdown {
    border-bottom: 1px solid #2979ff;
    -webkit-box-shadow: 0 1px 0 0 #2979ff;
    box-shadow: 0 1px 0 0 #2979ff;
}

/**
 Default .valid input border bottom to not colored when unfocused.
 Validate should not be here because valid and invalid are the ones to choose unfocused style.
 */
input.valid:not([type]),
input[type=text].valid:not(.browser-default),
input[type=password].valid:not(.browser-default),
input[type=email].valid:not(.browser-default),
input[type=url].valid:not(.browser-default),
input[type=time].valid:not(.browser-default),
input[type=date].valid:not(.browser-default),
input[type=datetime].valid:not(.browser-default),
input[type=datetime-local].valid:not(.browser-default),
input[type=tel].valid:not(.browser-default),
input[type=number].valid:not(.browser-default),
input[type=search].valid:not(.browser-default),
textarea.materialize-textarea.valid {
    border-bottom: 1px solid #9e9e9e !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/**
 Checkbox V (checked) icon to blue
 */
[type="checkbox"]:checked + label:before {
    border: 2px solid transparent;
    border-bottom: 2px solid #2979ff;
    border-right: 2px solid #2979ff;
    background: transparent;
}

.filled-in[type="checkbox"]:checked + label:after {
    border: 2px solid #2979ff;
    background: #2979ff;
}

.filled-in[type="checkbox"]:checked + label:before {
    border: 2px solid transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active {
    background-color: #2196f3;
}

/**
 Switch (checked) icon to blue
 */
.switch label input[type=checkbox]:checked + .lever:after {
    background-color: #2979ff;
}

.switch label input[type=checkbox]:checked + .lever {
    background-color: #2979ff8c;
}

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::before,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::before {
    background-color: #2979ff45;
}

.align-checkbox-right {
    float: right;
    padding-left: 27px !important;
}

.checkbox-label {
    font-size: unset;
    cursor: pointer;
}

/* input-location Vue component */
div[id^='input-location-'].invalid {
    margin-top: 20px;
}

.input-location {
    display: flex;
    margin-top: 15px;
    border-bottom: solid 1px #9e9e9e;
    justify-content: space-between;
    cursor: pointer;
}

.input-location-invalid {
    border-bottom: 1px solid #B00020;
    -webkit-box-shadow: 0 1px 0 0 #B00020;
    box-shadow: 0 1px 0 0 #B00020
}

.il-title {
    width: 70%;
}

.il-title > p {
    font-size: 12px;
}

.il-value {
    height: 20px;
    margin: 8px 0;
}

.il-badges {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
}

.il-badge {
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.icon {
    height: 24px;
    width: 24px;
}

.icon-modal-container {
    display: inline-flex;
    margin-top: 20px;
}

.icon-stairs {
    background-image: url(//static.goshare.co/icons/stairs.svg);
}

.icon-elevator {
    background-image: url(//static.goshare.co/icons/elevator.svg);
}

.icon-additional-info {
    background-image: url(//static.goshare.co/icons/message-text.svg);
}

.pac-container {
    z-index: 99999999999999 !important;
}


/* .fade Vue transition*/
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}


/* .slide Vue transition*/
.slide-enter-active {
    -moz-transition-duration: .4s;
    -webkit-transition-duration: .4s;
    -o-transition-duration: .4s;
    transition-duration: .4s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: .4s;
    -webkit-transition-duration: .4s;
    -o-transition-duration: .4s;
    transition-duration: .4s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

#vehicle-mobile-screen {
    display: none !important;
}

#vehicle-web-screen {
    display: block !important;
}

#vehicle-tabs {
    position: absolute;
    top: 0;
    left: 0;
}

.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0 !important;
}

@media screen and (max-width: 700px) {
    #vehicle-mobile-screen {
        display: block !important;
        margin-top: 30px;
    }

    #step-vehicle-title {
        display: none;
    }

    #vehicle-web-screen {
        display: none !important;
    }

}

img.circle, .chip > img, .user-view > img {
    border: 1px solid #e5e5e5;
    object-fit: cover;
}

/* Vue slide transition */
.slide-leave-active,
.slide-enter-active {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 156px;
}

.slide-enter,
.slide-leave-to {
    max-height: 0;
}

.dropdown-content li > a, .dropdown-content li > span {
    color: rgba(0, 0, 0, 0.87);
    font-weight: normal;
}
